import React from 'react';
import { Link as InertiaLink, type InertiaLinkProps } from '@inertiajs/react';
import { cn } from '@/Lib/utils';

const sharedClassname =
  'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:rounded-sm';

const Link = React.forwardRef<HTMLAnchorElement, InertiaLinkProps>(
  ({ className, href, ...props }, ref) => {
    return (
      <InertiaLink className={cn(sharedClassname, className)} ref={ref} href={href} {...props} />
    );
  },
);
Link.displayName = 'Link';

export const ExternalLink = React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { styled?: boolean }
>(({ className, styled = true, ...props }, ref) => {
  return (
    <a
      className={cn(
        sharedClassname,
        styled && 'font-semibold text-primary underline underline-offset-2',
        className,
      )}
      ref={ref}
      target="_blank"
      {...props}
    />
  );
});
ExternalLink.displayName = 'ExternalLink';

export default Link;
