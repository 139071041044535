import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function WaterHeaterIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M9.3 10.775q0 .475.163.925t.462.825q.05-.3.2-.587t.375-.488L12 10l1.475 1.475q.225.2.375.475t.2.575q.275-.375.487-.8t.213-.9t-.15-.913t-.45-.812q-.275.125-.562.2T13 9.375q-.75 0-1.375-.425t-.95-1.125q-.3.3-.55.637t-.437.713t-.288.775t-.1.825M12 12.1l-.425.425q-.1.1-.137.2t-.038.225q0 .25.175.4t.425.15t.425-.15t.175-.4q0-.125-.037-.225t-.138-.2zM12 5v1.9q0 .425.3.713t.725.287q.275 0 .5-.162t.4-.388l.175-.25q1.025.575 1.588 1.563t.562 2.162q0 1.75-1.25 2.963T12 15t-2.975-1.225T7.8 10.8q0-1.925 1.225-3.425T12 5M6 22q-.825 0-1.412-.587T4 20V6q0-1.65 1.175-2.825T8 2h8q1.65 0 2.825 1.175T20 6v14q0 .825-.587 1.413T18 22zm0-4v2h12v-2q-.75 0-1.2.5T15 19t-1.763-.5T12 18t-1.237.5T9 19t-1.763-.5T6 18m3-1q.825 0 1.238-.5T12 16t1.8.5t1.2.5t1.2-.5T18 16V6q0-.825-.587-1.412T16 4H8q-.825 0-1.412.588T6 6v10q1.35 0 1.763.5T9 17"
      ></path>
    </SVGIcon>
  );
}
