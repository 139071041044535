import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function LeafIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M12 20q-1.4 0-2.637-.437T7.1 18.325L5.7 19.7q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l1.375-1.375q-.8-1.025-1.237-2.275T4 12q0-3.35 2.325-5.675T12 4h6q.825 0 1.413.587T20 6v6q0 3.35-2.325 5.675T12 20m0-2q2.5 0 4.25-1.75T18 12V6h-6Q9.5 6 7.75 7.75T6 12q0 .975.3 1.863t.825 1.612L12.3 10.3q.275-.275.7-.275t.7.275q.3.3.3.713t-.3.712L8.525 16.9q.725.525 1.613.813T12 18m0-6"
      />
    </SVGIcon>
  );
}
