import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function WallIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M7 18v2q0 .425-.288.713T6 21t-.712-.288T5 20v-9.15l-2 1.525q-.35.25-.75.213T1.6 12.2t-.2-.75t.4-.65l8.975-6.875q.275-.2.588-.3t.637-.1t.638.1t.587.3L22.2 10.8q.325.25.388.65t-.188.75t-.65.388t-.75-.213l-2-1.525V20q0 .425-.288.713T18 21t-.712-.288T17 20v-2zm0-6h10v-2H7zm0 4h10v-2H7zm1.75-8h6.5L12 5.525z"
      ></path>
    </SVGIcon>
  );
}
