import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function DoorSlidingIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M9 13q-.425 0-.712-.288T8 12t.288-.712T9 11t.713.288T10 12t-.288.713T9 13m6 0q-.425 0-.712-.288T14 12t.288-.712T15 11t.713.288T16 12t-.288.713T15 13M4 21q-.425 0-.712-.288T3 20q0-.4.363-.562T4 19V5q0-.825.588-1.412T6 3h12q.825 0 1.413.588T20 5v14q.425 0 .713.288T21 20t-.288.713T20 21zm2-2h5V5H6zm7 0h5V5h-5zm-1-8"
      ></path>
    </SVGIcon>
  );
}
