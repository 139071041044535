import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function FanIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M10.6 22q-1.275 0-1.937-.763T8 19.5q0-.65.288-1.263t.887-1.012q.55-.35.888-.9t.462-1.175l-.3-.15q-.15-.075-.275-.175l-2.3.825q-.425.15-.825.25T6 16q-1.575 0-2.788-1.375T2 10.6q0-1.275.763-1.937T4.475 8q.65 0 1.275.288t1.025.887q.35.55.9.887t1.175.463l.15-.3q.075-.15.175-.275l-.825-2.3q-.15-.425-.25-.825t-.1-.8q0-1.6 1.375-2.813T13.4 2q1.275 0 1.938.763T16 4.475q0 .65-.288 1.275t-.887 1.025q-.55.35-.887.9t-.463 1.175l.3.15q.15.075.275.175l2.3-.85q.425-.15.813-.237T17.975 8Q20 8 21 9.675t1 3.725q0 1.275-.8 1.938T19.425 16q-.625 0-1.213-.288t-.987-.887q-.35-.55-.9-.887t-1.175-.463l-.15.3q-.075.15-.175.275l.825 2.3q.15.4.25.763t.1.762q.025 1.625-1.35 2.875T10.6 22m1.4-8.5q.625 0 1.062-.437T13.5 12t-.437-1.062T12 10.5t-1.062.438T10.5 12t.438 1.063T12 13.5m-1.15-4.8q.15-.05.313-.088t.312-.062q.2-1.05.763-1.95t1.487-1.5q.125-.1.2-.25T14 4.475q0-.2-.15-.337T13.4 4q-.95 0-2.15.413T10 6.025q0 .225.063.425t.112.375zM6 14q.35 0 .825-.175L8.7 13.15q-.05-.15-.088-.313t-.062-.312q-1.05-.2-1.95-.763t-1.5-1.487q-.1-.125-.262-.2T4.475 10q-.225 0-.35.15T4 10.6q0 1.35.513 2.375T6 14m4.6 6q1.175 0 2.313-.475T14 17.875q0-.2-.062-.375t-.113-.325L13.15 15.3q-.15.05-.312.088t-.313.062q-.2 1.05-.763 1.95t-1.487 1.5q-.125.1-.213.263T10 19.5q.025.2.15.35t.45.15m8.825-6q.225 0 .4-.125T20 13.4q0-.95-.4-2.162T17.975 10q-.225 0-.425.05t-.375.1l-1.875.7q.05.15.088.313t.062.312q1.05.2 1.95.763t1.5 1.487q.075.125.225.2t.3.075m-6.9 1.45"
      ></path>
    </SVGIcon>
  );
}
