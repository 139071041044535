import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function RoofIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M11 20q-.825 0-1.412-.587T9 18v-2q0-.825.588-1.412T11 14h2q.825 0 1.413.588T15 16v2q0 .825-.587 1.413T13 20zm0-2h2v-2h-2zm-8-5.6q-.35.25-.75.2t-.65-.4t-.187-.75t.387-.65l8.975-6.875q.275-.2.588-.3t.637-.1t.638.1t.587.3L16 6.05V5.5q0-.625.438-1.062T17.5 4t1.063.438T19 5.5v2.85l3.225 2.45q.325.25.375.65t-.2.75t-.65.4t-.75-.2l-9-6.875zm9 4.6"
      ></path>
    </SVGIcon>
  );
}
