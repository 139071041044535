import React from 'react';
import { cn } from '@/Lib/utils';
import Icon from '@/Components/Atoms/Icon';
import Link, { ExternalLink } from '@/Components/Atoms/Link';

interface Props {
  route: string;
  icon: string;
  label: string;
  active?: boolean;
  external?: boolean;
}

export default function SidebarLink({ route, icon, label, active, external }: Props) {
  const Comp = external ? ExternalLink : Link;

  return (
    <Comp
      href={route}
      className={cn(
        'flex items-center rounded p-1 text-sm font-medium hover:text-primary-foreground-accent max-sm:bg-gray-light max-sm:py-2 sm:max-md:px-0',
        active ? 'text-primary-foreground-accent' : 'text-muted-foreground',
      )}
      styled={false}
    >
      <Icon name={icon} className="mr-1 text-xl md:mr-2" />
      {label}
    </Comp>
  );
}
