import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function ChevronLeftIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="m10.8 12l3.9 3.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-4.6-4.6q-.15-.15-.212-.325T8.425 12t.063-.375t.212-.325l4.6-4.6q.275-.275.7-.275t.7.275t.275.7t-.275.7z"
      ></path>
    </SVGIcon>
  );
}
