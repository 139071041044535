import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function EuroIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M15 21q-2.95 0-5.25-1.675T6.5 15H4q-.425 0-.712-.288T3 14t.288-.712T4 13h2.05Q6 12.725 6 12.5v-1q0-.225.05-.5H4q-.425 0-.712-.288T3 10t.288-.712T4 9h2.5q.95-2.65 3.25-4.325T15 3q1.425 0 2.675.413t2.35 1.137q.425.275.438.775t-.338.85t-.875.413t-.975-.213q-.725-.425-1.562-.65T15 5.5q-1.875 0-3.413.963T9.25 9H14q.425 0 .713.288T15 10t-.288.713T14 11H8.6q-.05.275-.075.5T8.5 12t.025.5t.075.5H14q.425 0 .713.288T15 14t-.288.713T14 15H9.25q.8 1.575 2.338 2.538T15 18.5q.875 0 1.713-.213t1.562-.637q.45-.275.975-.225t.875.4t.338.85t-.438.775q-1.1.725-2.35 1.138T15 21"
      />
    </SVGIcon>
  );
}
