import React, { type PropsWithChildren, type SVGProps } from 'react';
import clsx from 'clsx';

import AreaIcon from './AreaIcon';
import ArrowDropDownIcon from './ArrowDropDownIcon';
import ArrowDropUpIcon from './ArrowDropUpIcon';
import AssignmentIcon from './AssignmentIcon';
import BalanceIcon from './BalanceIcon';
import BookmarkIcon from './BookmarkIcon';
import BrainIcon from './BrainIcon';
import CalendarIcon from './CalendarIcon';
import CarIcon from './CarIcon';
import ChevronLeftIcon from './ChevronLeftIcon';
import ChevronRightIcon from './ChevronRightIcon';
import CircleIcon from './CircleIcon';
import CloseIcon from './CloseIcon';
import CloseSmallIcon from './CloseSmallIcon';
import CompassIcon from './CompassIcon';
import DoneIcon from './DoneIcon';
import DoorSlidingIcon from './DoorSlidingIcon';
import EuroIcon from './EuroIcon';
import ExpandIcon from './ExpandIcon';
import FanIcon from './FanIcon';
import FolderEmptyIcon from './FolderEmptyIcon';
import FolderOpenIcon from './FolderOpenIcon';
import FlooringIcon from './FlooringIcon';
import HeatIcon from './HeatIcon';
import HeatPumpIcon from './HeatPumpIcon';
import HouseholdIcon from './HouseholdIcon';
import InfoOutlineIcon from './InfoOutlineIcon';
import LeafIcon from './LeafIcon';
import LocationIcon from './LocationIcon';
import MenuIcon from './MenuIcon';
import PersonAddIcon from './PersonAddIcon';
import PersonEditIcon from './PersonEditIcon';
import PersonIcon from './PersonIcon';
import PersonSearchIcon from './PersonSearchIcon';
import PlanIcon from './PlanIcon';
import ProgressActivityIcon from './ProgressActivityIcon';
import RoleIcon from './RoleIcon';
import RoofIcon from './RoofIcon';
import SearchIcon from './SearchIcon';
import SettingsIcon from './SettingsIcon';
import StoreyIcon from './StoreyIcon';
import SubdirectoryArrowRightIcon from './SubdirectoryArrowRightIcon';
import ThermalBridgeIcon from './ThermalBridgeIcon';
import ToolsIcon from './ToolsIcon';
import TrendingUpIcon from './TrendingUpIcon';
import WallIcon from './WallIcon';
import WarningIcon from './WarningIcon';
import WaterHeatIcon from './WaterHeatIcon';
import WaterHeaterIcon from './WaterHeaterIcon';

interface Props {
  className?: string;
  defaultSize?: boolean;
}

export type IconProps = PropsWithChildren<SVGProps<SVGSVGElement> & Props>;

export default function SVGIcon({ children, defaultSize = false, className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      className={clsx('inline shrink-0', { 'text-2xl': defaultSize }, className)}
      {...props}
    >
      {children}
    </svg>
  );
}

export {
  AreaIcon,
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  AssignmentIcon,
  BalanceIcon,
  BookmarkIcon,
  BrainIcon,
  CalendarIcon,
  CarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleIcon,
  CloseIcon,
  CloseSmallIcon,
  CompassIcon,
  DoneIcon,
  DoorSlidingIcon,
  EuroIcon,
  ExpandIcon,
  FanIcon,
  FolderOpenIcon,
  FolderEmptyIcon,
  FlooringIcon,
  HeatIcon,
  HeatPumpIcon,
  HouseholdIcon,
  InfoOutlineIcon,
  LeafIcon,
  LocationIcon,
  MenuIcon,
  PersonAddIcon,
  PersonEditIcon,
  PersonIcon,
  PersonSearchIcon,
  PlanIcon,
  ProgressActivityIcon,
  RoleIcon,
  RoofIcon,
  SearchIcon,
  SettingsIcon,
  StoreyIcon,
  SubdirectoryArrowRightIcon,
  ThermalBridgeIcon,
  ToolsIcon,
  TrendingUpIcon,
  WallIcon,
  WarningIcon,
  WaterHeatIcon,
  WaterHeaterIcon,
};
