import React from 'react';
import logo from '../../../images/renodit-white.svg';

interface Props {
  className?: string;
}

export default function ApplicationLogo({ className }: Props) {
  return <img src={logo} alt="Logo renOdit" className={className} />;
}
