import './bootstrap';
import '../css/app.css';

import './instrument';
import React, { type ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { RouteContext } from '@/Hooks/useRoute';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import AppLayout from '@/Layouts/AppLayout';
import * as Sentry from '@sentry/react';
import { type Auth } from '@/types';

import.meta.glob(['../images/**', '../fonts/**']);

dayjs.extend(localizedFormat);
dayjs.locale('fr');

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'renOdit';

void createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  progress: {
    color: '#4B5563',
  },
  resolve: async (name) => {
    const page = await resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob<ReactElement & { default: { layout: ReactElement } }>('./Pages/**/*.tsx'),
    );
    if (page && !name.startsWith('Auth/')) {
      page.default.layout =
        page.default.layout ||
        ((page: any) => (
          <AppLayout
            title={page.type.title}
            hasHeader={page.type.hasHeader}
            fullWidth={page.type.fullWidth}
            limitedWidth={page.type.limitedWidth}
          >
            {page}
          </AppLayout>
        ));
    }

    return page;
  },
  setup({ el, App, props }) {
    const userEmail = (props.initialPage.props.auth as Auth).user?.email;
    if (userEmail) {
      Sentry.setUser({ email: userEmail });
    }

    const root = createRoot(el);
    root.render(
      <RouteContext.Provider value={(window as any).route}>
        <App {...props} />
      </RouteContext.Provider>,
    );
  },
});
