import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function HouseholdIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M12 15q1.25 0 2.125-.875T15 12t-.875-2.125T12 9t-2.125.875T9 12t.875 2.125T12 15m0 1q-1.45 0-2.812.413T6.6 17.6q-.3.175-.45.475t-.15.65V19h12v-.275q0-.35-.15-.65t-.45-.475q-1.225-.775-2.587-1.187T12 16m-6 5q-.825 0-1.412-.587T4 19v-9q0-.475.213-.9t.587-.7l6-4.5q.525-.4 1.2-.4t1.2.4l6 4.5q.375.275.588.7T20 10v9q0 .825-.588 1.413T18 21z"
      ></path>
    </SVGIcon>
  );
}
