import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function BrainIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M9.75 21q-1.275 0-2.2-.888T6.5 17.976Q5 17.775 4 16.65T3 14q0-.525.138-1.037T3.55 12q-.275-.45-.412-.95T3 10q0-1.525 1-2.637T6.475 6.05Q6.55 4.775 7.5 3.888T9.75 3q.65 0 1.213.25T12 3.925q.45-.425 1.025-.675T14.25 3q1.3 0 2.238.875t1.012 2.15q1.475.2 2.488 1.325T21 10q0 .55-.137 1.05t-.413.95q.275.45.413.963T21 14q0 1.55-1.012 2.663t-2.513 1.312q-.125 1.25-1.037 2.138T14.25 21q-.625 0-1.213-.238T12 20.1q-.475.425-1.05.663T9.75 21M13 6.25v11.5q0 .525.363.888t.887.362q.5 0 .863-.4t.387-.9q-.525-.2-.962-.537t-.788-.813q-.25-.35-.187-.75t.412-.65t.75-.187t.65.412q.275.4.7.613T17 16q.825 0 1.413-.587T19 14q0-.125-.012-.25t-.063-.25q-.425.25-.913.375T17 14q-.425 0-.712-.288T16 13t.288-.712T17 12q.825 0 1.413-.587T19 10t-.587-1.4T17 8q-.275.45-.712.788t-.963.537q-.4.15-.775-.025t-.5-.575t.038-.775t.562-.5q.375-.125.613-.45t.237-.75q0-.525-.362-.887T14.25 5t-.888.363T13 6.25m-2 11.5V6.25q0-.525-.363-.888T9.75 5t-.888.363t-.362.887q0 .4.225.738t.6.462q.4.125.575.5t.05.775q-.15.4-.525.575t-.775.025q-.525-.2-.963-.537T6.975 8q-.8.025-1.387.613T5 10q0 .825.588 1.413T7 12q.425 0 .713.288T8 13t-.288.713T7 14q-.525 0-1.012-.125t-.913-.375q-.05.125-.062.25T5 14q0 .825.588 1.413T7 16q.5 0 .925-.213t.7-.612q.25-.35.65-.413t.75.188t.413.65t-.188.75q-.35.475-.8.825t-.975.55q.025.5.4.888T9.75 19q.525 0 .888-.363T11 17.75M12 12"
      />
    </SVGIcon>
  );
}
