import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function structuredClone<T>(obj: T): T {
    // TODO: use native structuredClone when available more widely
    return JSON.parse(JSON.stringify(obj));
}

export function isPostcodeInIdf(postcode: string): boolean {
    return [75, 77, 78, 91, 92, 93, 94, 95].includes(parseInt(postcode.substring(0, 2)));
}
