import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';
import { cn } from '@/Lib/utils';

export default function ProgressActivityIcon({ className, ...props }: IconProps) {
  return (
    <SVGIcon className={cn(className ?? '', 'animate-spin')} {...props}>
      <path
        fill="currentColor"
        d="M12 22q-2.05 0-3.875-.788t-3.187-2.15t-2.15-3.187T2 12q0-2.075.788-3.887t2.15-3.175t3.187-2.15T12 2q.425 0 .713.288T13 3t-.288.713T12 4Q8.675 4 6.337 6.338T4 12t2.338 5.663T12 20t5.663-2.337T20 12q0-.425.288-.712T21 11t.713.288T22 12q0 2.05-.788 3.875t-2.15 3.188t-3.175 2.15T12 22"
      ></path>
    </SVGIcon>
  );
}
