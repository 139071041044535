import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function HeatIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M13.3 20.725q-.15.125-.312.2t-.363.075t-.4-.088t-.35-.237q-1.025-1.1-1.513-2.25T9.876 16q0-.925.275-1.95t.95-2.65q.575-1.425.8-2.187t.225-1.413q0-.85-.375-1.587t-1.175-1.488q-.15-.15-.237-.35t-.088-.4t.075-.362t.2-.313q.15-.15.337-.225T11.25 3t.375.075t.325.2q1.1 1.025 1.637 2.15t.538 2.375q0 .875-.263 1.838t-.912 2.512q-.625 1.5-.85 2.3t-.225 1.525q0 .875.363 1.688T13.35 19.3q.125.15.2.325t.075.375t-.075.388t-.25.337m4.875 0q-.15.125-.312.2T17.5 21t-.4-.088t-.35-.237q-1.025-1.1-1.513-2.238t-.487-2.412q0-.925.275-1.975t.95-2.65q.575-1.425.8-2.175t.225-1.4q0-.85-.375-1.612T15.45 4.725q-.15-.15-.225-.337T15.15 4t.063-.363t.187-.312q.15-.15.35-.238t.4-.087t.363.075t.312.2q1.1 1.025 1.638 2.15T19 7.8q0 .875-.262 1.838t-.913 2.537q-.625 1.5-.85 2.3t-.225 1.5q0 .875.375 1.713t1.125 1.637q.125.15.187.325t.063.35q0 .2-.075.4t-.25.325m-9.75 0q-.15.125-.313.2T7.75 21t-.4-.088t-.35-.237q-1.025-1.1-1.513-2.238T5 16.025q0-.925.275-1.975t.95-2.65q.575-1.425.8-2.175t.225-1.4q0-.85-.375-1.612T5.7 4.725q-.175-.15-.25-.337T5.375 4t.075-.375t.2-.325q.15-.15.338-.225T6.375 3t.375.075t.325.2q1.1 1.025 1.638 2.138T9.25 7.8q0 .875-.25 1.838t-.9 2.512q-.625 1.5-.85 2.3t-.225 1.525q0 .875.363 1.713T8.5 19.325q.125.15.188.325t.062.35q0 .2-.075.4t-.25.325"
      ></path>
    </SVGIcon>
  );
}
