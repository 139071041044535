import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function WaterHeatIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M4 13.8q0-1.625.725-3.213T6.55 7.55t2.388-2.725t2.387-2.25q.15-.125.325-.187t.35-.063q.2 0 .362.063t.288.187q.975.85 1.975 1.813t1.9 2.037q.575.675 1.1 1.388t.95 1.462q.2.35.075.725t-.5.575t-.775.075t-.6-.475q-.75-1.25-1.937-2.638T12 4.65Q9.025 7.375 7.513 9.675T6 13.8q0 2.425 1.438 4.113t3.637 2.037q.525.075.75.413t.175.712t-.312.65t-.713.225q-3.1-.375-5.037-2.662T4 13.8m11.475 8.375q-.275.125-.562.013T14.5 21.8l-.125-.275q-.2-.425-.288-.887T14 19.7q0-.55.125-1.087t.375-1.038q.2-.425.35-.862T15 15.8q0-.375-.088-.725t-.237-.7l-.025-.05q-.125-.275-.013-.575t.388-.425t.562-.012t.413.387l.125.225q.2.425.288.9t.087.975q0 .55-.125 1.075T16 17.9q-.2.425-.35.875t-.15.925q0 .35.075.688t.225.662l.05.125q.125.275.013.575t-.388.425m3 0q-.275.125-.562.013T17.5 21.8l-.125-.275q-.2-.425-.288-.887T17 19.7q0-.55.125-1.087t.375-1.038q.2-.425.35-.862T18 15.8q0-.375-.088-.725t-.237-.7l-.025-.05q-.125-.275-.012-.575t.387-.425t.563-.012t.412.387l.125.225q.2.45.288.913t.087.962q0 .55-.125 1.075T19 17.9q-.2.425-.35.875t-.15.925q0 .35.075.688t.225.662l.05.125q.125.275.013.575t-.388.425m2.55-8.85q.275-.125.563-.012T22 13.7l.125.225q.2.425.288.9t.087.975q0 .55-.125 1.088T22 17.925q-.2.425-.35.863t-.15.912q0 .35.075.688t.225.662l.05.125q.075.15.075.3t-.05.275t-.15.238t-.25.187q-.275.125-.562.013T20.5 21.8l-.125-.275q-.2-.425-.288-.887T20 19.7q0-.55.125-1.087t.375-1.038q.2-.425.35-.862T21 15.8q0-.375-.088-.725t-.237-.7l-.025-.05q-.125-.275-.012-.575t.387-.425"
      ></path>
    </SVGIcon>
  );
}
