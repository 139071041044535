import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function CloseSmallIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="m12 13.4l-2.917 2.925q-.276.275-.704.275t-.704-.275t-.275-.7t.275-.7L10.6 12L7.675 9.108Q7.4 8.832 7.4 8.404t.275-.704t.7-.275t.7.275L12 10.625L14.892 7.7q.276-.275.704-.275t.704.275q.3.3.3.713t-.3.687L13.375 12l2.925 2.917q.275.276.275.704t-.275.704q-.3.3-.712.3t-.688-.3z"
      ></path>
    </SVGIcon>
  );
}
