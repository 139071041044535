import React from 'react';
import { cn } from '@/Lib/utils';
import Icon from '@/Components/Atoms/Icon';
import Link from '@/Components/Atoms/Link';

interface Props {
  route: string;
  icon: React.ElementType | string;
  label: string;
  active?: boolean;
}

export default function SidebarMenuItem({ route, icon: iconProp, label, active }: Props) {
  // const ItemIcon = typeof icon === 'string' ? Icon : icon;
  let icon;
  const CustomIcon = iconProp;

  if (typeof iconProp === 'string') {
    icon = <Icon className="w-full text-3xl sm:text-4xl" name={iconProp} />;
  } else {
    icon = <CustomIcon className="w-full text-3xl sm:text-4xl" />;
  }

  return (
    <Link
      href={route}
      className={cn(
        'group relative mx-1 rounded py-2 text-center transition-all ease-in-out hover:ml-2 hover:mr-0 max-sm:bg-gray-light',
        active ?
          'ml-2 mr-0 bg-gray text-primary-foreground-accent'
        : 'text-muted-foreground hover:bg-gray-light hover:text-primary-foreground-accent',
      )}
    >
      <div
        className={cn(
          'absolute inset-y-1/2 -left-2 w-1 rounded-lg transition-all ease-pop',
          active ?
            'bottom-0 top-0 bg-primary-foreground-accent'
          : 'bg-primary-foreground group-hover:inset-y-[37%] group-hover:block',
        )}
      />
      {icon}
      {label}
    </Link>
  );
}
