import React, { type SVGProps } from 'react';
import * as Icons from '../Icons';

type IconType = Record<string, React.ComponentType<any>>;
export type Props = SVGProps<SVGSVGElement> & {
  name: string;
  defaultSize?: boolean;
};

export default function Icon({ name, ...props }: Props) {
  // if name is camel-case string, we need to convert it to PascalCase
  name = name
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  // add the string "Icon" at the end of the name variable if the name is not already suffixed with "Icon"
  if (!name.endsWith('Icon')) {
    name += 'Icon';
  }

  const TypedIcons = Icons as IconType;

  const Icon = Object.prototype.hasOwnProperty.call(Icons, name) ? TypedIcons[name] : null;
  return Icon ? <Icon {...props} /> : null;
}
