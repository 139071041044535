import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/Lib/utils';
import Icon from '@/Components/Atoms/Icon';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap tracking-wide font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow hover:bg-primary/90 active:bg-primary/80',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90 active:bg-destructive/80',
        outline: 'border bg-background shadow-sm hover:shadow hover:text-accent-foreground',
        'destructive-outline':
          'border bg-background shadow-sm hover:text-accent-foreground hover:border-destructive active:bg-destructive/5',
        secondary:
          'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/90 active:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-9 rounded-md px-4 text-base',
        sm: 'h-8 rounded px-3 text-sm',
        lg: 'h-10 rounded-full px-4 text-base',
        icon: 'h-9 w-9 rounded-full text-base',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, icon, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    if (icon) {
      props.children = (
        <>
          {props.children}
          {icon && (
            <Icon
              name={icon}
              defaultSize
              className="relative left-1 transition-all ease-pop group-hover:left-2"
            />
          )}
        </>
      );
    }
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), 'group')}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
