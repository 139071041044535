import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function HeatPumpIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M20.175 7.6H19q-.425 0-.712.288T18 8.6v7q0 1.25-.875 2.125T15 18.6t-2.125-.875T12 15.6v-6q0-.425-.288-.712T11 8.6t-.712.288T10 9.6v6q0 1.25-.875 2.125T7 18.6t-2.125-.875T4 15.6v-8q0-.425.288-.712T5 6.6t.713.288T6 7.6v8q0 .425.288.713T7 16.6t.713-.288T8 15.6v-6q0-1.25.875-2.125T11 6.6t2.125.875T14 9.6v6q0 .425.288.713T15 16.6t.713-.288T16 15.6v-7q0-1.25.875-2.125T19 5.6h1.175l-.475-.475q-.275-.275-.275-.687t.275-.713q.3-.3.713-.3t.712.3L23.3 5.9q.3.3.3.7t-.3.7l-2.2 2.175q-.3.275-.713.288t-.687-.288t-.275-.725t.3-.7zM3 21.6q-.825 0-1.412-.587T1 19.6v-7q0-.425.288-.712T2 11.6h20q.425 0 .713.288T23 12.6v7q0 .825-.587 1.413T21 21.6zm0-2h18v-6H3zm18-6H3z"
      ></path>
    </SVGIcon>
  );
}
