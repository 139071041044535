import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

export default function BalanceIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M3 21q-.425 0-.712-.288T2 20t.288-.712T3 19h8V7.825q-.65-.225-1.125-.7T9.175 6H6l2.75 6.45q.125.275.15.563t-.025.587q-.225 1.15-1.237 1.775T5.5 16t-2.137-.625T2.125 13.6q-.05-.3-.025-.587t.15-.563L5 6H4q-.425 0-.712-.287T3 5t.288-.712T4 4h5.175q.3-.875 1.075-1.437T12 2t1.75.563T14.825 4H20q.425 0 .713.288T21 5t-.288.713T20 6h-1l2.75 6.45q.125.275.15.563t-.025.587q-.225 1.15-1.237 1.775T18.5 16t-2.137-.625t-1.238-1.775q-.05-.3-.025-.587t.15-.563L18 6h-3.175q-.225.65-.7 1.125t-1.125.7V19h8q.425 0 .713.288T22 20t-.288.713T21 21zm13.625-8h3.75L18.5 8.65zm-13 0h3.75L5.5 8.65zM12 6q.425 0 .713-.288T13 5t-.288-.712T12 4t-.712.288T11 5t.288.713T12 6"
      ></path>
    </SVGIcon>
  );
}
