import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

interface Props extends IconProps {
  open: boolean;
}

export default function MenuIcon(props: Props) {
  return (
    <SVGIcon {...props}>
      <path
        className="transition-colors"
        fill={props.open ? 'transparent' : 'currentColor'}
        d="M4 18q-.425 0-.712-.288T3 17t.288-.712T4 16h16q.425 0 .713.288T21 17t-.288.713T20 18zm0-5q-.425 0-.712-.288T3 12t.288-.712T4 11h16q.425 0 .713.288T21 12t-.288.713T20 13zm0-5q-.425 0-.712-.288T3 7t.288-.712T4 6h16q.425 0 .713.288T21 7t-.288.713T20 8z"
      ></path>
      <path
        className="transition-colors"
        fill={props.open ? 'currentColor' : 'transparent'}
        d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275t.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275z"
      ></path>
    </SVGIcon>
  );
}
